<template>
    <div class="role_index" id="roleList">
        <div class="role_content">
            

            <div class="role_button">
                <el-button type="primary" @click="addRole" style="margin-right: 50px">新增角色</el-button>

                <el-input placeholder="搜索角色名称" style="width: 250px" v-model="roleName" @keyup.enter.native="search" suffix-icon="el-icon-search"></el-input>
                
                
                
            </div>

            <div style="height: 20px; background: #F6F7F9; ">
                    
                </div>
                <div style="padding: 0 20px">
                    <h4 style="padding-top: 20px; margin-bottom: 20px">角色列表</h4>
                    <el-table border :data="tableData">
                        <!-- <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column> -->
                            <el-table-column prop="roleName" label="角色名称"></el-table-column>
                            <el-table-column prop="createTime" label="创建时间 "></el-table-column>
                            <el-table-column prop="roleUserTotal" label="人数"></el-table-column>
                            <el-table-column prop="remark" label="描述"></el-table-column>
                         
                            <el-table-column prop="name" label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">授权</el-button>
                                    <el-button type="text" @click="editRole(scope.row)" size="small">编辑</el-button>
                                    <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        @confirm="delRole(scope.row.id)"
                                        title="是否确认删除角色?"
                                        >
                                        <el-button slot="reference" type="text" style="margin-left: 12px" size="small">删除</el-button>
                                    </el-popconfirm>
                                    
                                </template>
                            </el-table-column>
                    </el-table>
                </div>
                <div style="text-align: right">
                    <div style="margin-top: 12px; display: inline-block">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageInfo.pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageInfo.total">
                        </el-pagination>
                    </div>
                </div>
        </div>
        

        <el-dialog :title="dialogTitle" width="40%" :visible.sync="roleDialog">
            <el-form :model="form" ref="cdForm" style="width: 80%; margin: 20px auto 0;" :rules="rules" label-width="80px">
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="form.roleName" autocomplete="off"></el-input>
                </el-form-item>

            
                <el-form-item label="描述" prop="remark">
                    <el-input v-model="form.remark" type="textarea" autocomplete="off"></el-input>
                </el-form-item>

                
                <el-form-item label="状态" prop="roleStatus">
                    <el-radio v-model="form.roleStatus" label="status_enabled">启用</el-radio>
                    <el-radio v-model="form.roleStatus" label="status_disabled">停用</el-radio>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="roleDialog = false">取 消</el-button>
                <el-button type="primary" @click="add">确 定</el-button>
            </div>
      </el-dialog>



        <el-dialog title="设置权限" width="30%" :visible.sync="dialogFormVisible">

            <el-form :model="form" ref="cdForm" style="width: 90%; margin: 20px auto 0;" label-width="80px">
                <el-form-item label="角色名称" prop="name">
                    <el-input readonly="" disabled v-model="form.roleName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权限合集">
                    <!-- <el-input v-model="form.url" autocomplete="off"></el-input> -->
                    <el-tree
                        class="filter-tree"
                        :data="data"
                        v-if="data.length"
                        :check-strictly="true" 
                        show-checkbox
                        :props="defaultProps"
                        :default-checked-keys="checkedList"
                        node-key="id"
                        default-expand-all
                        @check-change="getKeys"
                        ref="tree">
                    </el-tree>
                </el-form-item>
            
              
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureOk">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return {
            tableData: [],
            dialogFormVisible: false,
            form: {},
            roleName: '',
            dialogTitle: '新增角色',
            roleDialog: false,
            checkedList: [],
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            rules: {
                roleName: {required: true, message: '请输入角色名称', trigger: 'blur'}
            },
            selectedList: [],
            data: [],
            defaultProps: {
                children: 'children',
                label: 'moduleName'
            }
        }
    },
    mounted(){
        this.search();
        if (window.innerHeight){
            var winHeight = window.innerHeight;
        }
        else if ((document.body) && (document.body.clientHeight)){
            var winHeight = document.body.clientHeight;
        }
        document.getElementById("roleList").style.minHeight = (winHeight-90)+'px';
    },
    methods: {
        search(){
            let params = {
                pageNo: this.pageInfo.pageNo,
                pageSize: this.pageInfo.pageSize,
                roleName: this.roleName
            };
            this.qa.queryRole(params).then(res => {
                if(res.respCode == "0000") {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getKeys(data, check){
            
            // console.log(this.checkedList)
            // console.log(check)

        },

      addRole(){
          this.dialogTitle = "新增角色";
          this.form={
              roleName: '',
              roleStatus: 'status_enabled',
              remark: ''
          };
          this.roleDialog = true;
      },
      editRole(row){
          this.dialogTitle = "编辑角色";
          this.form={
              id: row.id,
              roleName: row.roleName,
              roleStatus: row.roleStatus,
              remark: row.remark
          };
          this.roleDialog = true;
      },
      handleClick(row){
          this.checkedList = [];
          this.form={
              id: row.id,
              roleName: row.roleName,
              roleStatus: row.roleStatus,
              remark: row.remark
          };
          this.qa.getManageRoleModeleByRoleId({id: row.id}).then(res => {
              if(res.respCode == "0000") {
                  this.data = this.FWZX.buildTree(res.data, 'moduleCode', 'moduleParentCode');
                  
                  for(let i = 0; i < res.data.length; i++) {
                      if(res.data[i].checked) {
                          this.checkedList.push(res.data[i].id);
                      }
                  }
                //   this.$refs.tree.setCheckedKeys(this.checkedList);

                  this.dialogFormVisible = true;
              } else {
                  this.$message.error(res.msg);
              }
          })
          
      },
      sureOk(){
        //   var vipList = this.$refs.tree.getCheckedKeys();
          let params = {
              id: this.form.id,
              moduleIdList: this.$refs.tree.getCheckedKeys()

          };
          this.qa.doAuthorizeRole(params).then(res => {
              if(res.respCode == "0000") {
                  this.$message({
                            message: '角色授权成功！',
                            type: 'success'
                        });
                        this.dialogFormVisible = false;
              } else {
                  this.$message.error(res.msg);
              }
          })
      },
      delRole(id){
                this.qa.delRole({id: id}).then(res => {
                    this.$message({
                    message: '删除成功',
                    type: 'success'
                    });
                    this.search();
                })

      },
      add(){
          this.$refs.cdForm.validate((valid) => {
            if (valid) {    
                if(this.dialogTitle == '新增角色') {
                    this.qa.addRole(this.form).then(res => {
                        if(res.respCode == "0000") {
                            this.$message({
                                    message: '新增角色成功！',
                                    type: 'success'
                                });
                                this.roleDialog = false;
                                this.search();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    this.qa.editRole(this.form).then(res => {
                        if(res.respCode == "0000") {
                            this.$message({
                                    message: '编辑角色成功！',
                                    type: 'success'
                                });
                                this.roleDialog = false;
                                this.search();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }
            } else {
                return false;
            }
          })
          
      },




      handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.search();
      },
      handleCurrentChange(page) {
          this.pageInfo.pageNo = page;
          this.search();
      }
    }
}
</script>

<style scoped>
  /* .role_index {
      width: 100%;
      padding: 20px 24px;
  } */
  .role_content {
      background: #fff;
      
  }
  .role_content >h4 {
      padding: 24px 24px 10px;
      font-size: 16px;
      color: #1D1E1F;
      font-weight: 400;
      line-height: 22px;
      border-bottom: 1px solid #EFEFEF;
  }
  .role_button {
      padding: 10px 24px;
  }
</style>